<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-card
    class="light invisible overflow-x-hidden"
    elevation="2"
    :style="{ 'max-height': 'calc(100vh - 240px)' }">
    <v-card-title class="py-3">
      <v-icon>mdi-filter-outline</v-icon>
      <h5 class="ml-2">Filtros</h5>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-divider></v-divider>

      <!-- <v-card-text class="global-recomended black--text">
          Recomendados
        </v-card-text>
        <div>
          <v-chip
            v-if="chip"
            close
            @click:close="close"
            small
            class="global-chip ml-3"
            label
            >{{ checked }}</v-chip
          >
        </div>
  
        <div class="ml-3">
          <v-checkbox
            v-for="(item, i) in checkbox"
            :key="i"
            :color="btnColor"
            class="global-chck"
            v-model="checked"
            :label="item.label"
            :value="item.value"
            hide-details
          />
        </div> -->

      <p class="ml-3 mt-6 mb-0 d-flex">
        <span>Precio</span> <v-spacer></v-spacer>
        <v-tooltip right color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="$emit('sort-price')"
              color="primary"
              v-on="on"
              v-bind="attrs"
              class="mr-2"
              x-small
              fab
              text
              ><v-icon>{{
                sortPrice
                  ? "mdi-sort-numeric-ascending"
                  : "mdi-sort-numeric-descending"
              }}</v-icon></v-btn
            >
          </template>
          <span
            >Ordenado de forma
            {{ sortPrice ? "ascendente" : "descendente" }}</span
          >
        </v-tooltip>
      </p>

      <div class="pr-3 pl-3 mb-3">
        <v-range-slider
          track-color="#F1F1F1"
          :track-fill-color="btnColor"
          :thumb-color="btnColor"
          :thumb-size="50"
          v-model="value"
          :max="priceLimit"
          validate-on-blur
          name="barra_rangos"
          :min="lowerPriceLimit">
        </v-range-slider>
        <div class="d-flex">
          <p class="global-value ml-2" :style="{ color: textColor }">
            Min <span v-if="country === countries.CL">$</span
            ><span v-else>S/</span
            >{{
              value[0].toLocaleString(
                country === countries.CL ? "es-CL" : "es-PE"
              )
            }}
          </p>
          <v-spacer></v-spacer>
          <p class="global-value mr-2" :style="{ color: textColor }">
            Max <span v-if="country === countries.CL">$</span
            ><span v-else>S/</span
            >{{
              value[1].toLocaleString(
                country === countries.CL ? "es-CL" : "es-PE"
              )
            }}
          </p>
        </div>
      </div>

      <v-expansion-panels accordion focusable flat multiple>
        <v-expansion-panel
          v-for="(item, i) in panels"
          :key="i"
          class="mb-2"
          name="paneles">
          <v-divider v-if="item.show"></v-divider>
          <v-expansion-panel-header
            v-show="item.show"
            class="title-color-background-opacity semiBold"
            expand-icon="mdi-chevron-down"
            >{{ item.text }}</v-expansion-panel-header
          >
          <v-expansion-panel-content v-if="item.show">
            <v-text-field
              v-if="item.search"
              :placeholder="item.searchPlaceholder"
              v-model="searchers[item.searchModel]"
              @input="item.searchMethod"
              :color="btnColor"
              outlined
              dense
              class="mt-4"
              hide-details
              clearable
              :name="item.searchName" />

            <v-checkbox
              @change="HandleCheckBoxChange((i = item.selectKey))"
              v-model="form[item.model]"
              v-for="(i, index) in item.items"
              :key="index"
              class="global-chck"
              :label="i.label"
              :value="i.value"
              :color="btnColor"
              hide-details
              :multiple="item.multiple"
              :name="`${item.selectKey}_${index}`" />

            <!-- <div v-else>Test {{ item.id }}</div> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
// import Icon from "@/components/Icon/Icon.vue";
import { COUNTRIES } from "@/helpers/constants";

export default {
  props: {
    priceLimit: {
      type: Number,
      default: 0,
      description: "Limite de precio",
    },
    lowerPriceLimit: {
      type: Number,
      default: 0,
      description: "Limite de precio inferior",
    },
    companyList: {
      type: Array,
      default: () => [],
      description: "Listado del filtro de companias",
    },
    /* exclusividades: {
      type: Array,
      default: () => [],
      description: "Listado del filtro de exclusividad",
    }, */
    deducibleList: {
      type: Array,
      default: () => [],
      description: "Listado del filtro de deducible",
    },
    vigencias: {
      type: Array,
      default: () => [],
      description: "Listado del filtro de vigencias",
    },
    productList: {
      type: Array,
      default: () => [],
      description: "Listado del filtro de productos",
    },
    sortPrice: {
      type: Boolean,
      default: true,
      description: "Ordenar precio, True ascendente, False descendente",
    },
  },
  components: {
    // Icon,
  },
  data() {
    return {
      countries: COUNTRIES,
      min: 0,
      value: [0, 0],
      form: {
        cobertura: "",
        //exclusividad: [],
        vigencia: [],
        companias: [],
        deducibles: [],
        products: [],
      },
      searchers: {
        companySelected: "",
        deducibleSelected: "",
        productSelected: "",
      },
      filtered: {
        deducibles: [],
        companies: [],
        products: [],
      },

      checkbox: [
        { label: "Todos", value: "Todos" },
        { label: "Más Vendido", value: "Más Vendido" },
        { label: "Más Económico", value: "Más Económico" },
        { label: "Mejor Comisión", value: "Mejor Comisión" },
      ],
      Cobertura: [
        { label: "Daño a terceros", value: "Daño a terceros" },
        { label: "65% de pérdida total", value: "65% de pérdida total" },
        {
          label: "Robo, Hurto no autorizado",
          value: "Robo, Hurto no autorizado",
        },
      ],
      checked: "Todos",
      check: "",
      chip: true,
    };
  },
  created() {
    this.filtered.deducibles = this.deducibleList;
    this.filtered.companies = this.companyList;
    this.filtered.products = this.productList;
    this.value = [0, this.priceLimit];
  },

  watch: {
    value() {
      this.$emit("pricevalue", this.value);
    },
  },
  computed: {
    ...mapGetters("Users", ["getBotonColor", "getTextoColor"]),
    country() {
      return this.$store.getters.getCountry;
    },

    panels() {
      return [
        {
          id: 2,
          text: "Coberturas",
          show: false,
          search: false,
          searchPlaceholder: "",
          selectKey: "cobertura",
          searchMethod: () => null,
          multiple: false,
          model: "cobertura",
          searchModel: null,
          searchName: "cobertura_texto",
          items: [],
        },
        {
          id: 3,
          text: this.$i18n.messages[this.$i18n.locale].companies,
          show: true,
          search: true,
          searchPlaceholder: "Buscar compañía...",
          selectKey: "companias",
          searchMethod: this.SearchCompany,
          multiple: true,
          model: "companias",
          searchModel: "companySelected",
          searchName: "companias_texto",
          items: this.filtered.companies,
        },
        {
          id: 4,
          text: "Deducibles",
          show: true,
          search: true,
          searchPlaceholder: "Buscar deducibles...",
          selectKey: "deducibles",
          searchMethod: this.SearchDeducibles,
          multiple: true,
          model: "deducibles",
          searchModel: "deducibleSelected",
          searchName: "deducibles_texto",
          items: this.filtered.deducibles,
        },
        /* { id: 5, text: "Exclusividad", show: true, search: false, selectKey: 'exclusividad', searchMethod: () => null, }, multiple: false, model: 'exclusividad*/
        {
          id: 6,
          text: "Vigencias",
          show: true,
          search: false,
          searchPlaceholder: "",
          selectKey: "vigencia",
          searchMethod: () => null,
          multiple: true,
          model: "vigencia",
          searchModel: null,
          searchName: "vigencia_texto",
          items: this.vigencias,
        },
        {
          id: 7,
          text: "Productos",
          show: true,
          search: true,
          searchPlaceholder: "Buscar producto...",
          selectKey: "producto",
          searchMethod: this.SearchProduct,
          multiple: true,
          model: "products",
          searchModel: "productSelected",
          searchName: "producto_texto",
          items: this.filtered.products,
        },
      ];
    },

    btnColor() {
      return this.getBotonColor;
    },
    textColor() {
      return this.getTextoColor;
    },
  },
  methods: {
    // close() {
    //   this.checked = "";
    //   this.chip = false;
    // },
    HandleCheckBoxChange(i) {
      let valueSelected, filterSelected;
      switch (i) {
        case "cobertura": {
          filterSelected = "filter-coberturas";
          valueSelected = this.form.cobertura;
          break;
        }
        case "companias": {
          filterSelected = "filter-companies";
          valueSelected = this.form.companias;
          break;
        }
        case "deducibles": {
          filterSelected = "filter-deducibles";
          valueSelected = this.form.deducibles;
          break;
        }
        /* case "exclusividad": {
          filterSelected = "filter-exclusividad";
          valueSelected = this.form.exclusividad;
          break;
        } */
        case "vigencia": {
          filterSelected = "filter-vigencia";
          valueSelected = this.form.vigencia;
          break;
        }

        case "producto": {
          filterSelected = "filter-products";
          valueSelected = this.form.products;
          break;
        }

        default: {
          filterSelected = "no-filter";
          valueSelected = null;
          break;
        }
      }
      this.$emit(filterSelected, valueSelected);
    },
    SearchDeducibles() {
      const dedu = this.deducibleList;
      if (this.searchers.deducibleSelected) {
        this.filtered.deducibles = dedu.filter(
          (item) => item.value == this.searchers.deducibleSelected
        );
      } else {
        this.filtered.deducibles = this.deducibleList;
      }
    },
    SearchCompany() {
      const companyList = this.companyList;
      if (this.searchers.companySelected) {
        this.filtered.companies = companyList.filter((company) =>
          company.label
            .toLowerCase()
            .includes(this.searchers.companySelected.toLowerCase())
        );
      } else {
        this.filtered.companies = this.companyList;
      }
    },
    SearchProduct() {
      const prod = this.productList;
      if (this.searchers.productSelected) {
        this.filtered.products = prod.filter((item) =>
          item.label
            .toLowerCase()
            .includes(this.searchers.productSelected.toLowerCase())
        );
      } else {
        this.filtered.products = this.productList;
      }
    },
  },
};
</script>

<style scoped>
.v-input >>> label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #050c42;
}

.v-expansion-panel-header--active {
  background-color: #e3eeff !important;
}
:deep(.v-input--selection-controls__input .v-icon) {
  width: 100%;
}
.ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
</style>
