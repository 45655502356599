<template>
  <div class="text-center" v-if="open">
    <v-dialog
      v-model="dialog"
      max-width="930px"
      height="327px"
      attach="#main-container"
    >
      <v-card class="global-modalCard3">
        <v-layout fill-height>
          <v-flex class="text-left pl-10 pt-10">
            <h3 class="global-titleStyle11 mt-6">¡Pre inspección enviada!</h3>

            <h4 class="mt-6 global-subtitleStyle8">
              La solicitud de pre inspección fue enviada <br />
              al
              <strong class="global-subtitleStyle8 blue--text">
                RUT {{ dataUser.numero_documento | rutFormat }}
                <span class="black--text">de</span> {{ dataUser.cliente }} .
              </strong>
              <br />Favor de esperar y consultar al correo en estos días.
            </h4>
          </v-flex>

          <v-flex class="d-flex align-center justify-center">
            <div
              id="ComputerSvg"
              style="align-items: center; justify-content: center"
            >
              <v-img
                class="mt-8"
                max-width="250px"
                src="../../assets/modalImages/pana.png"
              />
              <v-btn v-on:click="action" class="mt-7" width="214" id="primary">
                Entendido
              </v-btn>
            </div>

            <v-img
              max-width="450px"
              width="450.5px"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    dialog: true,
  }),
  props: {
    open: Boolean,
    dataUser: Object,
    action: Function,
  },
  methods: {
    ...mapActions("Users", ["findUser"]),
    close() {
      var data = { rut: this.userData.numero_documento };
      this.findUser(data).then((res) => {
        if (res) {
          this.$router.push({
            name: "UserDetail",
          });
        }
      });
      this.$emit("closeDialog", true);
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 430px;
}
#ComputerSvg {
  z-index: 10;
}
</style>
