<template>
  <div class="d-flex w-100 h-100">
    <v-card
      flat
      :style="`${bgc()}`"
      class="light w-100 br-12 invisible"
      elevation="3">
      <div class="w-100 d-flex justify-center primary-bg">
        <span class="semiBold text-white">{{
          Information.objeto_vigencia.vigencia_descripcion
        }}</span>
      </div>

      <v-card-text class="d-flex flex-column h-100">
        <div class="d-flex w-100 justify-center align-center">
          <div class="">
            <v-fade-transition>
              <v-img
                :src="Information[logoName]"
                :alt="Information.compania_descripcion"
                :title="Information.compania_descripcion"
                @error="handleLogoError($event, Information)"
                max-width="95"
                max-height="60"
                contain>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-fade-transition>
          </div>
        </div>
        <div class="text-center my-1">
          <h6 class="mb-1 p" v-if="country === 'CL'">
            {{ Information.nombre_producto }}
          </h6>
          <span class="h6 primary--text"
            >Deducible
            <span class="bold">
              {{ Information.moneda_seguro.simbolo }}
              {{ Information.deducible }}</span
            >
          </span>
        </div>
        <v-divider :style="`${divider()}`"></v-divider>
        <div class="d-flex justify-space-between flex-column mt-2">
          <div class="d-flex justify-space-between">
            <span>Prima Referencia</span>
            <h5 class="bold">
              {{ Information.moneda_local?.simbolo }}
              {{ Information.moneda_local.precio }}
            </h5>
          </div>
          <div class="d-flex justify-space-between mt-2">
            <span>Prima Mensual</span>
            <span class="h5 bold primary--text">
              {{ Information.moneda_seguro.simbolo }}
              {{ Information.moneda_seguro.precio }}</span
            >
          </div>
        </div>
        <div class="d-flex justify-space-between mb-4" style="margin-top: auto">
          <v-btn
            text
            small
            class="text-none primary--text p"
            @click="actionButton(Information)">
            Ver más
          </v-btn>
          <v-btn
            @click="CreatePropuesta(Information)"
            class="text-none"
            color="primary"
            rounded
            small
            :disabled="disabledContinue"
            :loading="loading">
            <span class="semiBold h5 text-white"> Continuar</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <!-- <v-card flat :style="`${bgc()}`" max-height="290">
      <v-card-text class="pa-0" @click="actionButton(Information)">
        <div
          class="global-upperchip rounded-bl-xl float-right white--text"
          :style="`${bgci()}`"
        >
          <v-icon color="white" class="mr-2 ml-3">{{ `${icon()}` }}</v-icon>
          <span
            id="global-span"
            v-if="Information.destacado_descripcion !== 'Más Comisión'"
            >{{ Information.destacado_descripcion }}
          </span>
          <span
            id="global-span"
            v-if="Information.destacado_descripcion === 'Más Comisión'"
            >Mejor Comisión
          </span>
        </div>
        <div class="global-imgdiv mb-3">
          <v-img
            class="global-image2"
            :src="getPicture(Information)"
            :alt="Information.compania_descripcion"
            :title="Information.compania_descripcion"
          />
        </div>
        <h4>{{ Information.nombre_producto }}</h4>
        <h3>DEDUCIBLE UF {{ Information.deducible }}</h3>

        <div class="d-flex">
          <v-divider class="mt-6" :style="`${divider()}`"></v-divider>
          <div class="global-discount .rounded-l-xl">
            <span>
              <p class="font-weight-bold">
                {{ Information.objeto_vigencia.vigencia_descripcion }} -->
    <!-- <v-icon color="white" small> mdi-tag-multiple </v-icon> -->
    <!-- {{ descount }} -->
    <!-- </p>
            </span>
          </div>
        </div>
        <v-layout class="mt-4">
          <v-flex>
            <div class="">
              <span class="ml-3 global-primaReferencia"> PRIMA REFERENCIA</span>
            </div>
          </v-flex>
          <v-flex class="d-flex justify-end">
            <span
              class="global-details-price mt-1 mr-2 success--text text--lighten-1"
            >
              {{ Information.moneda_local.precio | moneyFormat }} -->
    <!-- {{ Information.moneda_local.simbolo }}{{ Information.moneda_local.precio }} -->
    <!-- </span>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex>
            <div class="">
              <span class="ml-3 global-primaReferencia"> PRIMA MENSUAL</span>
            </div>
          </v-flex>
          <v-flex class="d-flex justify-end">
            <span
              class="global-details-price mt-1 mr-2 primary--text text--lighten-1"
            >
              {{ Information.moneda_seguro.precio | UFPrice }} -->
    <!-- {{ Information.moneda_seguro.simbolo }} {{ Information.moneda_seguro.precio }} -->
    <!-- </span>
          </v-flex>
        </v-layout>
      </v-card-text> -->
    <!-- <v-divider></v-divider> -->
    <!-- <v-card-actions>
      <v-layout class="d-flex">
        <v-flex>
          <template> <slot name="header" class="ml-2"></slot></template>
        </v-flex>
        <v-flex class="d-flex align-center justify-end">
          <v-btn
            x-small
            @click="actionButton(Information)"
            text
            :color="btnColor"
            class="plusBtn text-capitalize"
          >
            <i class="bi bi-plus-circle mr-1" />
            Ver más
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions> -->
    <!-- </v-card> -->
  </div>
</template>
<script>
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
export default {
  mixins: [LogoRequestMixin],
  name: "Oferta",
  props: {
    descount: {
      type: String,
      require: true,
    },
    sales: {
      type: String,
      require: true,
    },
    Information: {
      type: [Object],
      require: true,
    },
    id: {
      type: Number,
      require: true,
    },
    SelectItem: {
      type: Function,
      default(Informacion) {
        return [{ data: Informacion }];
      },
    },
    actionButton: {
      type: Function,
      default(Informacion) {
        return Informacion;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabledContinue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    country() {
      return this.$store.getters.getCountry;
    },
  },
  methods: {
    CreatePropuesta(v) {
      this.$emit("CreatePropuesta", v);
    },
    bgc() {
      if (this.Information.destacado_descripcion === "Más Comisión") {
        if (this.Information.seleccionada === true)
          return "border: 3px solid #0077c8";
        else return "border: 1px solid #906DCF";
      }
      if (this.Information.destacado_descripcion === "Más Económico") {
        if (this.Information.seleccionada === true)
          return "border: 3px solid #0077c8";
        else return "border: 1px solid #59BB71";
      }
      if (this.Information.destacado_descripcion === "Más Vendido") {
        if (this.Information.seleccionada === true)
          return "border: 3px solid #0077c8";
        else return "border: 1px solid #4CB7C8";
      }
      if (this.Information.destacado_descripcion === "Sin destacar") {
        if (this.Information.seleccionada === true)
          return "border: 3px solid #0077c8";
        else return "border: 1px solid #D7D7D7";
      } else {
        return "";
      }
    },
    divider() {
      if (this.Information.destacado_descripcion === "Más Comisión") {
        return "background: #906DCF";
      }
      if (this.Information.destacado_descripcion === "Más Económico") {
        return "background: #59BB71";
      }
      if (this.Information.destacado_descripcion === "Más Vendido") {
        return "background: #4CB7C8";
      }
      if (this.Information.destacado_descripcion === "Sin destacar") {
        return "background: white";
      } else {
        return "gray";
      }
    },
    bgci() {
      if (this.Information.destacado_descripcion === "Más Comisión") {
        return "background: #906DCF";
      }
      if (this.Information.destacado_descripcion === "Más Económico") {
        return "background: #59BB71";
      }
      if (this.Information.destacado_descripcion === "Más Vendido") {
        return "background: #4CB7C8";
      }
      if (this.Information.destacado_descripcion === "Sin destacar") {
        return "background: white";
      } else {
        return "grey";
      }
    },
    icon() {
      if (this.Information.destacado_descripcion === "Más Comisión") {
        return "mdi-arrow-up-circle-outline";
      }
      if (this.Information.destacado_descripcion === "Más Económico") {
        return "mdi-cash-multiple";
      }
      if (this.Information.destacado_descripcion === "Más Vendido") {
        return "mdi-star-outline";
      }
      if (this.Information.destacado_descripcion === "Sin destacar") {
        return "";
      } else {
        return "mdi-check-circle-outline";
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin-top: 100px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;

  margin-left: 20px;
  line-height: 22px;

  color: #0077c8;
}
h4 {
  margin-top: 40px;
  position: absolute;
  margin-left: 20px;

  font-style: normal;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 17px;

  font-size: 20px;

  font-weight: 700;

  color: #050c42;
}
p {
  font-size: 14px;
  color: white !important;
  margin-left: 15px;
  margin-top: 3px;
}
.btn {
  height: 30px;
  border-radius: 5px;
}

:deep(.plusBtn) {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.right {
  position: absolute;
  right: 10px;
}
</style>
