import axios from "axios";
import {
  companyNumber,
  companyLogoUrl,
  defaultLogoPropName,
} from "@/services/file.js";

export const obtenerCotizacionOfertas = async (id) => {
  try {
    const url = `/cotizaciones/${id}`;
    const res = await axios.get(url);
    const cotizacionData = res.data.data;
    const OfferList = cotizacionData?.tarifas?.ofertas ?? [];

    const {
      lowerPriceLimit,
      priceLimit,
      companyList,
      deducibleList,
      vigenciaList,
      productTypeList,
    } = await procesarOfertas(OfferList);

    return {
      cotizacionData,
      lowerPriceLimit,
      priceLimit,
      companyList,
      deducibleList,
      vigenciaList,
      productTypeList,
    };
  } catch (error) {
    throw new Error(
      "Ha ocurrido un error al intentar recuperar las ofertas de la cotizacion"
    );
  }
};

async function procesarOfertas(OfferList) {
  const lists = {
    companyList: [],
    deducibleList: [],
    vigenciaList: [],
    productTypeList: [],
  };

  let lowerPriceLimit = OfferList[0]?.moneda_local?.precio ?? 0;
  let priceLimit = 0;

  await Promise.all(
    OfferList.map(async (i, index) => {
      // === Anexar info extra a la data ===
      i.loading = false;
      i.Identification = JSON.stringify(index);

      // === Establecer el precio limite para el filtro ===
      priceLimit = Math.max(i.moneda_local.precio, priceLimit);
      lowerPriceLimit = Math.min(i.moneda_local.precio, lowerPriceLimit);

      // === Establecer el listado para el filtro de companias ===
      lists.companyList = agregarUnico(
        lists.companyList,
        i[companyNumber],
        i.compania_descripcion,
        index
      );
      // === Establecer el listado para el filtro de deducibles ===
      lists.deducibleList = agregarUnico(
        lists.deducibleList,
        i.deducible,
        `Deducible UF ${i.deducible}`,
        index
      );
      // === Establecer el listado para el filtro de Vigencias ===
      lists.vigenciaList = agregarUnico(
        lists.vigenciaList,
        i.objeto_vigencia.vigencia,
        i.objeto_vigencia.vigencia_descripcion,
        i.objeto_vigencia.id_vigencia
      );
      // === Establecer el listado para el filtro de tipo de producto ===
      lists.productTypeList = agregarUnico(
        lists.productTypeList,
        i.tipo_producto._id,
        i.tipo_producto.tipo_producto_descripcion ?? "",
        i.tipo_producto._id
      );

      // === Establecer el logo de la compania (Solo si amerita) ===
      i[defaultLogoPropName] = i[companyLogoUrl];
    })
  );

  return {
    ...lists,
    lowerPriceLimit: Math.ceil(lowerPriceLimit),
    priceLimit: Math.ceil(priceLimit),
  };
}

function agregarUnico(array, value, label, id) {
  // === Verificar que el objeto no exista ya en el arreglo ===
  if (value !== undefined && !array.some((e) => e.value === value)) {
    array.push({ id, value, label });
  }
  return array;
}

export const descargarCotizacion = (prop = null, id = "") => {
  const url = `cotizaciones/${id}/descargar`;
  const data = {
    planes: [{ plan: prop.plan }],
  };
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      data,
    })
      .then(async (response) => {
        const result = response.data.data;
        if (result?.documentos?.length > 0) {
          const ResponseFile = result.documentos[0];
          const base64Data = ResponseFile.documento;
          const base64Response = await fetch(
            `data:${ResponseFile.mime};base64,${base64Data}`
          );
          const blob = await base64Response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", ResponseFile.nombre); //any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          throw Error("No se han encontrado documentos.");
        }
        resolve(
          `Se ha generado el archivo para la cotización nro ${id} satisfactoriamente!`
        );
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const compartirCotizacion = (prop = null, id = "") => {
  const url = `cotizaciones/${id}/compartir`;
  const data = {
    planes: [{ plan: prop.plan }],
  };
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      data,
    })
      .then((res) => {
        const result = res.data.data;
        resolve(
          result?.lista_mensaje?.[0]?.mensaje ??
            `Se ha compartido la cotización nro ${id} satisfactoriamente!`
        );
      })
      .catch(() => {
        reject(
          new Error("Ha ocurrido un error al intentar compartir la cotización")
        );
      });
  });
};

export default {
  descargarCotizacion,
};
